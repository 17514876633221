<template>
  <div :class="[ 'flex flex-col-reverse lg:flex-row rounded-none lg:rounded-b-panel z-2', 'h-fit lg:h-125', link ? 'cursor-pointer' : '' ]">
    <div :class="[ 'w-full lg:w-137.5 h-full flex-shrink-0 bg-menu-dark lg:bg-primary-dark p-5 lg:p-13 rounded-b-panel lg:rounded-none lg:rounded-bl-panel text-light', imgSize.size === 'imageLarge' ? 'radial-gradient' : 'radial-gradient' ]">
      <Transition name="fade" mode="out-in">
        <div v-if="heading" class="w-full h-full flex flex-col items-center lg:items-start justify-center lg:justify-end" @click="handleClick">
          <h1 :class="[ 'mb-0 lg:mb-4 font-bold lg:font-normal text-center lg:text-left hyphens-none', imgSize.size === 'imageSmall' ? 'text-2xl' : '', imgSize.size === 'imageMedium' ? 'text-4xl' : '', imgSize.size === 'imageLarge' ? 'text-6xl' : '' ]" v-html="heading" />
          <p :class="[ 'font-normal text-center sm:text-left hyphens-none', imgSize.size === 'imageSmall' ? 'text-base' : '', imgSize.size === 'imageMedium' ? 'text-xl' : '', imgSize.size === 'imageLarge' ? 'text-3xl' : '' ]" v-html="subheading" />
        </div>
      </Transition>
    </div>
    <div :class="[ 'flex w-full overflow-hidden z-1 lg:rounded-none lg:rounded-br-panel bg-primary-dark', imgSize.size === 'imageLarge' ? 'h-125' : 'h-auto' ]">
      <Swiper
        id="bannerGallery"
        ref="bannerGallery"
        :space-between="0"
        :slides-per-view="1"
        :loop="true"
        :centered-slides="true"
        :pagination="{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => renderBullet(index, className)
        }"
        :speed="2000"
        class="w-full"
        :modules="gallery.length > 1 ? [Autoplay, Pagination] : []"
        @click="handleClick"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <SwiperSlide
          v-for="(item, idx) in gallery"
          :key="`banner-gallery-${idx}`"
          class="w-full"
        >
          <div v-if="imgSrc" :class="[ 'relative w-full overflow-hidden flex justify-center items-center', imgSize.size === 'imageLarge' ? 'h-125' : 'h-auto' ]">
            <div :class="[ 'w-full h-full flex items-center justify-center', imgSize.size === 'imageLarge' ? 'absolute' : '' ]" style="left: -1px; right: -1px; width: calc(100% + 2px);">
              <NuxtImg
                :src="item[imgSize.size]"
                format="webp"
                :alt="heading"
                :width="imgSize.width"
                :height="imgSize.height"
                fit="cover"
                :modifiers="{ rotate: null }"
                :class="[ 'w-full h-full', imgSize.size === 'imageLarge' ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover' : '' ]"
                :style="imgSize.size === 'imageLarge' ? `width: ${imgSize.width}px; height: ${imgSize.height}px;` : `width: 100vw; height: auto;`"
              />
            </div>
          </div>
        </SwiperSlide>
        <div class="swiper-pagination"></div>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { Autoplay, Pagination } from 'swiper/modules';
import { getKeyValue } from "@utils/get-key-value";
import { sitePages } from "@settings/site-pages.settings";
import { useEnumStore } from '@stores/enum';
import Image from "@components/ui/image";

const { t } = useI18n();
const enums = useEnumStore();
const getPageData = getKeyValue(sitePages, 'aquarist');
const siteBanners = _.map(getPageData.main, banner => ({
  imageSmall: banner.image,
  imageMedium: banner.image,
  imageLarge: banner.image,
  title: t(banner.heading),
  content: t(banner.subheading),
  url: translatePath(banner.link)
}));

const gallery = computed(() => _.isEmpty(enums.banners) ? siteBanners : enums.banners);

const windowSize = useWindowSize();
const slideIndex = ref(-1);
const heading = ref('');
const subheading = ref('');
const link = ref('');
const imgSrc = ref('');
const bannerGallery = ref(null);
const imgSize = ref({ width: 320, height: 84, size: 'imageSmall' });

const updateImgSize = (value) => {
  if (process.client) {
    if (value >= 1024) {
      imgSize.value = { width: 1500, height: 500, size: 'imageLarge' };
    } else if (value >= 768) {
      imgSize.value = { width: 768, height: 202, size: 'imageMedium' };
    } else {
      imgSize.value = { width: 320, height: 84, size: 'imageSmall' };
    }
  } else {
    imgSize.value = { width: 320, height: 84, size: 'imageSmall' };
  }
}

watch(() => windowSize.width.value, (value) => updateImgSize(value));

const renderBullet = (index, className) => {
  return `<span class="${className}">&nbsp;</span>`;
};

const onSlideChange = (swiper) => {
  if (slideIndex.value >= 0) {
    heading.value = '';
    subheading.value = '';
  }
  slideIndex.value = swiper.realIndex;
  nextTick(() => {
    heading.value = gallery.value[slideIndex.value].title;
    subheading.value = gallery.value[slideIndex.value].content;
    link.value = gallery.value[slideIndex.value].url;
    imgSrc.value = gallery.value[slideIndex.value][imgSize.value.size];
  });
};

const handleClick = async () => {
  if (link.value) {
    await navigateTo(link.value, { external: !_.startsWith(link.value, '/') });
  }
}

const onSwiper = (swiper) => {
  if (swiper && swiper.params && gallery.value.length) {
    swiper.params.autoplay = {
      delay: 5000,
      disableOnInteraction: false,
    };
    swiper.autoplay.start();
  }
};

const initSlides = (value = null) => {
  nextTick(() => {
    windowSize.updateSize();
    updateImgSize(windowSize.width.value);
    heading.value = gallery.value[value ? slideIndex.value : 0].title;
    subheading.value = gallery.value[value ? slideIndex.value : 0].content;
    link.value = gallery.value[value ? slideIndex.value : 0].url;
    imgSrc.value = gallery.value[value ? slideIndex.value : 0][imgSize.value.size];
  });
}

watch(() => enums.banners, initSlides);

onMounted(() => {
  heading.value = '';
  subheading.value = '';
  initSlides();
});

</script>

<style scoped>
.radial-gradient {
  background-image: radial-gradient(circle at 30% 50%, #4B7F3D, #0D2712 70%);
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
</style>

<style>
.swiper-pagination-bullet {
  background: #F1F2EB;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.3rem;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #A4FB7B;
}
</style>
