<template>
    <div class="flex flex-col items-center justify-center my-8 sm:my-15 p-5 overflow-x-hidden">
        <div class="flex flex-col sm:flex-row items-center justify-between w-full md:w-156 lg:w-239 2xl:w-322 mb-2.5 py-4">
            <div class="flex flex-col mb-5 sm:mb-5">
                <div class="text-2xl font-bold text-center sm:text-left">
                    {{ $t(`text-latest-${contentType}`) }}
                </div>
                <div class="text-base font-normal text-center sm:text-left">
                    {{ $t(`text-latest-${contentType}-subtitle`) }}
                </div>
            </div>
            <div class="flex items-center justify-end space-x-5 flex-shrink-0 ml-5">
                <Button
                    variant="outline"
                    size="menu"
                    :on-click="handleShowMore"
                >
                    <span class="text-base">{{ $t('text-show-more') }}</span>
                </Button>
                <div class="flex items-center justify-end space-x-2">
                    <MenuButton
                        variant="base"
                        :disabled="page === 0"
                        :on-click="handlePrev"
                    >
                        <ChevronLeft class="w-3 h-3" />
                    </MenuButton>
                    <MenuButton
                        variant="base"
                        :disabled="Math.floor(pagination.itemCount / gridSize.width.value / 2) === page"
                        :on-click="handleNext"
                    >
                        <ChevronRight class="w-3 h-3" />
                    </MenuButton>
                </div>
            </div>
        </div>
        <div v-if="loading || !isMounted" class="w-full flex justify-center">
          <div class="w-full sm:w-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-9 gap-y-2.5 sm:gap-y-9">
            <ContentFeedLoader :limit="isMounted ? gridSize.width.value * 2 : 2" />
          </div>
        </div>
        <div v-else id="cardsView" class="w-full flex justify-center">
          <div v-if="gridPage.length" class="w-full sm:w-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-9 gap-y-2.5 sm:gap-y-9">
            <RenderContentCard
                v-for="(item) in gridPage"
                :key="item?.variant?.id"
                :show="item.show"
                :variant="item.variant"
                :type="contentType"
                :class-name="item.hidden ? 'hidden' : ''"
            />
          </div>
        </div>
        <div class="w-full flex items-center justify-center mt-6.5 sm:mt-9">
          <Button
                variant="outline"
                size="menu"
                :on-click="handleShowMore"
          >
                <span class="text-base">{{ $t('text-show-more') }}</span>
          </Button>
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { PRODUCT_SUBTYPE, CONTENT_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth'
import { useSelectedStore } from '@stores/selected'
import Button from "@components/ui/button";
import MenuButton from "@components/ui/menu-button";
import ChevronLeft from "@components/icons/chevron-left";
import ChevronRight from "@components/icons/chevron-right";
import ContentFeedLoader from "@components/ui/loaders/content-feed-loader";
import RenderContentCard from "@components/content/render-content-card";

const props = defineProps({
  prefix: {
    type: String,
    required: true
  },
  subtype: {
    type: String,
    required: true
  },
  basePath: {
    type: String,
    required: true
  },
  contentType: {
    type: String,
    required: true
  },
  sort: {
    type: String,
    default: 'publishedAt'
  }
});

const config = useRuntimeConfig();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const auth = useAuthStore();
const store = useSelectedStore();
const category = useState('route.category', () => 'aquarist');
const loading = ref(false);
const isMounted = ref(false);
const page = ref(0);
const pages = useState(`contents.${props.subtype}.pages.${category.value}`, () => []);
const pagination = useState(`contents.${props.subtype}.pagination.${category.value}`, () => ({ page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 }));
const gridSize = useGridSize();
const variants = useState(`contents.${props.subtype}.variants.${category.value}`, () => []);
const gridPage = ref([]);
const fader = useFader(variants, page, gridSize, gridPage);
const routeLang = useRouteLang();

const handleShowMore = async () => {
    await navigateTo(translatePath(props.basePath));
}

const reload = async () => {
  if (loading.value) {
    return;
  }
  if (page.value === 0) loading.value = true;

  try {
    const data = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.CONTENT_POST}`, {
      method: 'POST',
      params: {
        type: props.contentType,
        page: pagination.value.page,
        limit: pagination.value.itemsPerPage,
        sort: props.sort,
        direction: 'desc',
        locale: routeLang.locale.value
      }
    });

    pages.value.push(data);
    pagination.value = data.pagination;
    fader.addVariants(data?.items || []);
    fader.scheduleShow(page.value, page.value);
  } catch (error) {
    if (process.client && !auth.reloadTimeout) {
      $toast.error(t(`error.${props.prefix}-detail.load`));
    }
    Sentry.captureException(error);
  }
  if (page.value === 0) loading.value = false;
}

watch(() => store.loadReset, async values => {
  if (values[props.subtype]) {
    fader.setVariants([]);
    pages.value = [];
    pagination.value.page = 1;
    await reload();
    fader.resetShow(gridSize.width.value);
    store.setLoadReset(props.subtype, false);
  }
}, { deep: true });

onServerPrefetch(async () => {
  pagination.value.page = 1;
  // await reload();
});

const contentUpdate = (value) => {
  const variant = _.find(variants.value, { variant: { id: value.id } });
  if (variant) {
    _.assignIn(variant.variant, value);
  }
  const gridItem = _.find(gridPage.value, { variant: { id: value.id } });
  if (gridItem) {
    _.assignIn(gridItem.variant, value);
  }
  if (variant || gridItem) {
    store.setLoadResetOthers();
  }
};

onMounted(async () => {
  isMounted.value = true;
  if (_.isEmpty(pages.value) || store.loadReset[props.subtype] || auth.isLoggedIn) {
    store.setLoadReset(props.subtype, false);
    fader.setVariants([]);
    pages.value = [];
    pagination.value.page = 1;
    await reload();
  }
  fader.resetShow(gridSize.width.value);
  $eventBus.on('content:update', contentUpdate);
});

onUnmounted(() => {
  $eventBus.off('content:update', contentUpdate);
});

const handlePrev = () => {
    page.value = Math.max(0, page.value - 1);
}

const handleNext = async () => {
    page.value = Math.min(Math.floor(pagination.value.itemCount / gridSize.width.value / 2), page.value + 1);
    if ((page.value + 1) * gridSize.width.value * 2 >= variants.value.length) {
        pagination.value.page++;
        await reload();
    }
}

</script>
