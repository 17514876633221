<template>
  <div :class="['reference-card cart-type-aquarist w-full sm:w-74 h-full transition-all duration-200 transform flex flex-col items-center justify-center', className, `reference-card-${variant.id}`]" @click.prevent="handleContentQuickView">
    <Avatar :src="variant?.supplier?.logo" :title="variant?.supplier?.name" size="reference" />
    <h3 class="text-lg uppercase font-bold text-center my-2 line-clamp-1" v-text="variant?.supplier?.name" />
    <div v-if="variant?.ratingScore" class="flex flex-row items-center justify-center space-x-2 mt-2">
      <StarSolid v-for="(index, idx) in _.range(0, variant?.ratingScore)" :key="idx" class="w-6 h-6 text-orange hover:text-orange-hover" />
    </div>
    <div v-if="localized(variant, 'perex', localized(variant, 'content'))" class="w-full mt-4 mb-2 text-base text-primary-dark font-normal text-center">
      {{ localized(variant, 'perex', localized(variant, 'content')) }}
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { STATUS } from "@utils/constants";
import { siteSettings } from "@settings/site.settings";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import Avatar from "@components/ui/avatar";
import StarSolid from "@components/icons/solid/star-solid";

const props = defineProps({
  variant: {
    type: Object,
    required: true 
  },
  className: {
    type: String,
    required: false,
    default: () => '' 
  }
})

const auth = useAuthStore();
const store = useSelectedStore();
const { $eventBus } = useNuxtApp();

const supplierName = computed(() => {
  const str = props.variant?.supplier?.name || '';
  const middleIndex = Math.floor(str.length / 2);
  let leftIndex = str.lastIndexOf(' ', middleIndex);
  let rightIndex = str.indexOf(' ', middleIndex + 1);
  if (rightIndex === -1) {
    rightIndex = str.length;
  }
  if (leftIndex === -1) {
    leftIndex = 0;
  }
  const useLeft = (middleIndex - leftIndex) < (rightIndex - middleIndex);
  const splitIndex = useLeft ? leftIndex : rightIndex;
  const first = _.trim(_.slice(str, 0, splitIndex).join(''));
  const last = _.trim(_.slice(str, splitIndex + 1).join(''));
  return { first, last };
});


const handleContentQuickView = async (action = null) => { 
  // store.setAction(action);
  // store.setContent(props.variant);
  // $eventBus.emit('modal:open', 'REFERENCE_DETAILS');
};

</script>
