<template>
  <div>
    <ActivationAlert :inline="true" :centered="windowSize.width.value < 768" />
    <TopCategoryMenu />
    <CarouselBanner />
    <ProductPanel />
    <CategoryPanel />
    <ContentPanel prefix="content" :subtype="PRODUCT_SUBTYPE.imain" :base-path="ROUTES.INSPIRATION" :content-type="CONTENT_TYPE.INSPIRATION" />
    <MobileAppPanel />
    <ContentPanel prefix="news" :subtype="PRODUCT_SUBTYPE.nmain" :base-path="ROUTES.NEWS" :content-type="CONTENT_TYPE.NEWS" sort="order" />
    <ReferencePanel prefix="reference" :subtype="PRODUCT_SUBTYPE.rmain" :base-path="ROUTES.ADS" :content-type="CONTENT_TYPE.REFERENCE" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_SUBTYPE, CONTENT_TYPE } from "@utils/constants";
import { sitePages } from "@settings/site-pages.settings";
import ActivationAlert from "@components/profile/activation-alert";
import TopCategoryMenu from "@components/category/top-category-menu";
import CarouselBanner from "@components/common/carousel-banner";
import ContentPanel from "@components/content/content-panel/content-panel";
import CategoryPanel from "@components/category/category-panel";
import ProductPanel from "@components/product/product-panel/product-panel";
import MobileAppPanel from "@components/mobile-app/mobile-app-panel";
import ReferencePanel from "@components/reference/reference-panel/reference-panel";

definePageMeta({
  layout: 'home-layout'
});

defineI18nRoute({
  paths: {
    cs: '/',
    en: '/',
    sk: '/',
    pl: '/',
    de: '/'
  }
});

const windowSize = useWindowSize();
const config = useRuntimeConfig();
const canonical = config.public.appBaseUrl + translatePath(ROUTES.HOME);

const configs = await loadPublicConfig();

const title = _.get(_.find(configs || [], { identifier: 'title' }), 'value', null);
const description = _.get(_.find(configs || [], { identifier: 'description' }), 'value', null);

useHead({ link: [ { rel: 'canonical', href: canonical } ] });
useSeoMeta({
  title: title || `${sitePages.aquarist.title}`,
  ogTitle: title || `${sitePages.aquarist.title}`,
  description: description || `${sitePages.aquarist.description}`,
  ogDescription: description || `${sitePages.aquarist.description}`,
  ogImage: `${sitePages.aquarist.image}`,
  ogType: `${sitePages.aquarist.type}`,
  ogUrl: canonical
});

</script>
