<template>
    <div class="flex flex-col items-center justify py-8 lg:py-20 px-5 bg-primary-dark text-light">
        <div class="flex flex-col lg:flex-row items-center justify-between w-full md:w-156 lg:w-239 2xl:w-322 mb-6.5">
            <div class="flex flex-col mb-5 lg:mb-5">
                <div class="text-2xl font-bold">
                    {{ $t('text-category') }}
                </div>
                <div class="text-base font-normal hidden lg:block">
                    {{ $t('text-category-subtitle') }}
                </div>
            </div>
        </div>
        <div v-if="!isMounted" class="w-full h-71.5" />
        <div v-else class="w-full flex justify-center">
          <div v-if="gridSize.width.value > 2" class="w-full sm:w-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-9 gap-y-2.5 sm:gap-y-9">
            <Link 
                v-for="(item, idx) in siteSettings.categoryHomePanel"
                :key="item.id"
                :href="`${translatePath(ROUTES.CATEGORY)}/${slugify(item?.id, _.find(flatCategories, { id: item.id })?.name)}`"
            >
                <MenuButton
                    size="jumbo"
                    variant="category"
                    :class-name="idx >= gridSize.width.value ? 'hidden' : ''"
                >
                    <div class="flex flex-col items-center justify-center p-9">
                        <CategoryIcon :icon="item.icon" />
                        <div class="text-2xl mt-6">
                            {{ item.label ? $t(item.label) : _.find(flatCategories, { id: item.id })?.name }}
                        </div>
                    </div>
                </MenuButton>
            </Link>
          </div>
          <div v-else class="w-full lg:w-fit grid grid-cols-2 gap-5">
            <Link 
                v-for="(item, idx) in siteSettings.categoryHomePanel"
                :key="item.id"
                :href="`${translatePath(ROUTES.CATEGORY)}/${slugify(item?.id, _.find(flatCategories, { id: item.id })?.name)}`"
            >
                <MenuButton
                    size="full"
                    variant="category"
                >
                    <div class="flex flex-col items-center justify-center p-5 lg:p-9">
                        <CategoryIcon :icon="item.icon" class="w-20 h-20" />
                        <div class="text-base mt-4">
                            {{ item.label ? $t(item.label) : _.find(flatCategories, { id: item.id })?.name }}
                        </div>
                    </div>
                </MenuButton>
            </Link>
          </div>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import { useCategoryStore } from '@stores/category';
import CategoryIcon from "@components/ui/icon/category-icon";
import MenuButton from "@components/ui/menu-button";
import Link from "@components/ui/link/link";

const categories = useCategoryStore();
const gridSize = useGridSize();
const isMounted = ref(false);

const flatCategories = computed(() => _.concat(categories.eshop, _.flatMap(categories.eshop, (item) => item?.categories || []) || []));

onMounted(async () => {
  isMounted.value = true;
});

</script>
