<template>
    <div class="flex flex-col items-center justify p-5 linear-gradient">
        <div class="flex flex-col lg:flex-row items-center justify-between w-full md:w-156 lg:w-239 2xl:w-322">
            <div class="flex flex-col my-8 sm:my-15 w-full lg:w-1/2 pr-5 sm:pr-20">
                <div class="text-4xl sm:text-6xl font-bold">
                    {{ $t('text-mobile-app-title') }}
                </div>
                <div class="text-2xl sm:text-3xl font-normal py-4">
                    {{ $t('text-mobile-app-subtitle') }}
                </div>
                <div class="flex flex-nowrap pt-6">
                  <div :class="[ 'shrink-0 flex items-center justify-start', deviceLinks.length > 1 ? 'pr-5' : '' ]">
                    <a
                      v-if="links?.android"
                      :href="links.android"
                    >
                      <img :src="`/buttons/${routeLang.code.value}/google-play-badge.png`" :class="[ 'w-auto mb-5', deviceLinks.length > 1 ? 'h-12 sm:h-21' : 'h-21' ]" loading="lazy" />
                    </a>
                  </div>
                  <div class="shrink-0 flex items-center justify-start">
                    <a
                      v-if="links?.ios"
                      :href="links.ios"
                    >
                      <img :src="`/buttons/${routeLang.code.value}/apple-store-badge.svg`" :class="[ 'w-auto mb-5', deviceLinks.length > 1 ? 'h-10 sm:h-16' : 'h-16' ]" loading="lazy" />
                    </a>
                  </div>
                </div>
            </div>
            <Image
                src="/mobile-app-preview.png"
                :alt="$t('text-mobile-app')"
                :width="651"
                :height="658"
            />
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import UAParser from 'ua-parser-js';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { getDeviceInfo } from "@utils/get-device-info";
import Image from "@components/ui/image";

const config = useRuntimeConfig();
const routeLang = useRouteLang();
const { ssrContext } = useNuxtApp();
const { t } = useI18n();
const loading = ref(true);
const links = ref({});

const userAgent = process.client ? navigator.userAgent : ssrContext?.event?.req?.headers['user-agent'];
const { os } = getDeviceInfo(userAgent, routeLang.locale.value);
const device = (os || '').startsWith('Android') ? 'android' : (os === 'iOS' ? 'ios' : os);

const deviceLinks = computed(() => _.filter(_.values(links.value)));

const reload = async () => {
    try {
      links.value = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.APP}`, {
        method: 'GET',
        params: { 
          device, 
          locale: routeLang.locale.value 
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    loading.value = false;
}

onMounted(async () => {
    await reload();
});

</script>

<style scoped>
.linear-gradient {
  background-image: linear-gradient(to bottom right, rgba(164, 251, 123, 0.6) 40%, rgba(250, 238, 69, 0.6));
}
</style>
